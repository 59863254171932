import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo";
import SubHeader from '../components/subheader'
import ContactSection from '../components/contact-section'

const About = () => {
    return (
        <Layout>
            <SEO title="Contact Us" />
            <SubHeader title='Contact Us'/>
            <ContactSection/>
        </Layout>
    )
}

export default About